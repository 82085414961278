<template>
  <div>
    <!--Labs Area Start-->
    <div class="breadcrumb-banner-area">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="breadcrumb-text">
              <h1 class="text-center" >{{ LabsT }}</h1>
              <!-- <p id="postSubTitleId" class="text-center"></p> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="teachers-area" v-show="showLabs">
      <div class="container">
        <div class="row">
          <div v-for="labs in Labs" :key="labs.postId" class="col-lg-4">
            <div class="single-teacher-item single-teacher-item1">
              <div class="single-teacher-image single-teacher-image1">
                <a
                  ><img
                    width="100%"
                    height="auto"
                    loading="lazy"
                    v-bind:src="
                      'https://api2.yuniv.net:444/images/post/' + labs.postImage
                    "
                    alt=""
                /></a>
              </div>
              <div class="single-event-item single-event-item1">
                <div class="single-event-text single-event-text2">
                  <h3>
                    <router-link
                      href="#"
                      aria-label="title"
                      :to="{
                        name: 'ComputerLabs',
                        params: { id: labs.postID },
                      }"
                      >{{ labs.postTitle }}</router-link
                    >
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div
            class="col-md-12 col-sm-12"
            style="text-align: left; padding: 30px 0 0"
          >
          </div>
        </div>
      </div>
    </div>
    <!--End of Labs Area-->
  </div>
</template>

<script>
import axios from "axios";
import ArJson from "../../public/i18n/Ar.json";
import EnJson from "../../public/i18n/En.json";

export default {
  data() {
    return {
      showLabs: true,
      LabsT: "",
      Labs: [],
      ArTranslat: ArJson,
      EnTranslat: EnJson,
      translate: "",
    };
  },
  mounted() {
    var self_ = this;
    if (localStorage.getItem("lang") == null) {
      axios({
        method: "get",
        url: "https://api2.yuniv.net:444/languages/getLangIsMain",
      }).then(function (response) {
        localStorage.setItem("lang", response.data[0]["LangId"]);
        self_.getApis();
      });
    } else self_.getApis();
  },
  methods: {
    getApis() {
      window.scroll(0, 0);
      var self = this;

      if (localStorage.getItem("lang") == "Ar") {
        self.translate = self.ArTranslat;
      } else {
        self.translate = self.EnTranslat;
        let link1 = document.createElement("link");
        link1.setAttribute("rel", "stylesheet");
        link1.id = "langfilewebsites";
        link1.setAttribute("href", "./style-ltr.css");
        document.head.appendChild(link1);
      }
      self.LabsT = self.translate[0]["HeaderAndFooter"]["Labs"];

    
          var bodyFormDataL = new FormData();
          bodyFormDataL.append("check", "getPost");
          bodyFormDataL.append("PostId", "");
          bodyFormDataL.append("Lang", localStorage.getItem("lang"));
          bodyFormDataL.append("FbrnId", "");
          bodyFormDataL.append("Type", "Univ.labs");
          bodyFormDataL.append("ParentId", "NTROOT0");
          bodyFormDataL.append("PostName", "");
          bodyFormDataL.append("Pno", "-1");
          axios({
            method: "post",
            url: "https://api2.yuniv.net:444/our_team/getPost",
            data: bodyFormDataL,
          }).then(function (response) {
            var arr = response.data;
            if (arr.length != 0) {
              self.showLabs = true;
              self.Labs = response.data;
            } else {
              self.showLabs = false;
            }
            document.getElementById("VueMainJsNewTouch")?.remove();
            let recaptchaScript = document.createElement("script");
            recaptchaScript.setAttribute("src", "/js/main.js");
            recaptchaScript.id = "VueMainJsNewTouch";
            document.head.appendChild(recaptchaScript);
          });
    },
  },
};
</script>
